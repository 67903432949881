// fonts
/** Sizes */
$baseFontSize: 16px;
$bp-1k: 120em;
$bp-2k: 160em;

/** Media Query */
$mq-2k: "(min-width: #{$bp-2k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";

$fontBase: 'Inria Serif', serif;

/** Transition time */
$transitionTime: 0.5s;
$radius: 60px;

// Colors Brand

$brand-primary: #101010;
$brand-secondary: #EE1347;
$brand-third: #fff;


//Shadow
$shadow-1: rgba(0, 0, 0, 0.09);

html,
body {
    font-family: $fontBase;
    font-size: $baseFontSize;
    color: $brand-primary;

    @media #{$mq-2k} {
        font-size: 16px*1.3;
    }

}

main {
    position: relative;
    overflow: hidden;
}
