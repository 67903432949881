#content {
    &.sticky {

        .VehicleOwnMenu,
        header,.notifyBar {
            transform: translateY(-58px);

            @include media-breakpoint-up(md) {
                transform: translateY(-71px);
            }


            @include media-breakpoint-up(lg) {
                transform: translateY(-70px);
            }

        }
    }

    &.noScroll {
        height: calc(100vh - 58px);
        overflow: hidden;

        @include media-breakpoint-up(md) {
            height: calc(100vh - 70px);
        }
    }
}

// msg on orientation device to prevent desmadre
.turnOff {
    display: none;
}

.do-overflow {
    overflow: hidden;
}


/***********************************************************
GENERAL ELEMENTS
***********************************************************/
a:hover {
    color: #191919;
}

a:active,
a:hover {
    text-decoration: none;

}

a[href*="tel"] {
    color: $brand-secondary;
}

a,
button {
    border: none;

    &:focus {
        outline: none;
        border: none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

//fix slick spaces
.slick-slide {
    line-height: 0;
}

//box whit shadow
.ShadowBox {
    border-radius: 7px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

//show-hide tabs
.hideTab {
    display: none;
}

.showTab {
    display: block;
}

picture {
    width: 100%;
}

//custom Select


select:focus {
    outline: none;
}

.LineSelect {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0;
    width: 100%;
    padding-right: 20px;
    color: $brand-third;
    -moz-appearance: none;
    appearance: none;

    &.back-white {
        background: url("/images/arrow-down-b.svg") #ffffff no-repeat right center;
    }
}

/***********************************************************
TEXT COLORS
***********************************************************/

.txtColorLight {
    color: $brand-third;
}

.txtColorPrimary {
    color: $brand-primary;
}
.txtColorPrimary-forced {
    color: $brand-primary !important;
}

.txtColorSecondary {
    color: $brand-secondary;
}

.textThin {
    font-weight: lighter;
}

/***********************************************************
BG COLORS
***********************************************************/

.bgColorLight {
    background: $brand-third;
}

.bgColorPrimary {
    background: $brand-primary;
}

.bgColorSecondary {
    background: $brand-secondary;
}

/***********************************************************
TITLES
***********************************************************/

.SecTitle {
    color: $brand-secondary;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        font-size: 50px;
        margin-bottom: 50px;
    }


    @media #{$mq-2k} {
        font-size: 60px;
    }
}


/***********************************************************
ACTIONS
***********************************************************/

.BtnStd {
    background-color: $brand-secondary;
    border-radius: 30px;
    border-bottom: 0;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    height: 56px;
    min-width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    width: 200px;
    transition: all 0.5s ease-out;

    @media #{$mq-2k} {
        min-width: 225px;
    }

    &.disabled {
        background-color: #df1d4d !important;
        opacity: 0.7;

        .iArrow,
        .iArrow:focus,
        .iArrow:hover {
            display: none !important;

        }
    }

    &.aDark {
        background-color: $brand-primary;
    }

    &-light {
        background-color: $brand-third;
        color: $brand-primary;
    }

    &.w-shadow {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.29);
    }

    @include hover {
        color: $brand-primary;
    }
}

.SingleLink {
    display: inline;
    font-size: em(12px);
    position: relative;
    padding-bottom: 5px;

    &::after {
        background-color: $brand-primary;
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

/***********************************************************
    CONTAINER
    ***********************************************************/
.container {
    @media #{$mq-2k} {
        max-width: 1550px;
    }
}

label {
    &.error {
        color: $brand-secondary;
    }
}

//generic inut style
input,
textarea {
    color: $brand-primary;
    background-color: transparent;
    border-bottom: solid 1px #065196;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 22px;
    height: 45px;
    width: 100%;
    padding-left: 20px;

    @media #{$mq-2k} {
        font-size: 16px;
    }

    &:focus {
        outline: none;
    }

    &.error {
        border: 1px solid $brand-secondary;
    }
}

::-webkit-input-placeholder {
    /* Edge */
    color: $brand-third;
    font-size: 16px;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $brand-third;
    font-size: 16px;
}

::placeholder {
    color: $brand-third;
    font-size: 16px;
}

.generic-car {
    margin: auto;
}

.no-margin {
    margin: 0 !important;
}
.w-margin {
    margin-left: 15px;
    margin-right: 15px;
}
.no-padding {
    padding: 0 !important;
}
.w-padding {
    padding-left: 15px;
    padding-right: 15px;
}
.w-padding-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.self-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}