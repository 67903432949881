.bg-ii-blue-500 {
    background-color: #053456;
}

.bg-hover-ii-blue-500 {
    &:hover {
        background-color: #053456;
    }
}

.bg-ii-yellow-500 {
    background-color: #fec42e;
}

.bg-hover-ii-yellow-500 {
    &:hover {
        background-color: #fec42e;
    }
}

.ga-banner {
    background-color: $brand-third;
    position: relative;
    min-height: 585px;
    transition: all 0.5s ease-out;

    &.library {
        min-height: 270px;
    }

    @include media-breakpoint-up(md) {
        min-height: auto;
    }

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: all 0.5s ease-out;

        .homeHeading {
            color: white;
            text-align: center;
            transition: all 0.5s ease-out;

            img {
                width: 405px;
            }

            h1 {
                font-size: 16px;
                font-style: italic;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 60px;
                margin-top: 20px;
                width: 265px;
                margin-left: auto;
                margin-right: auto;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 21px;
                    text-align: center;
                    width: 100%;
                }
            }

            h2 {
                border-bottom: 1px solid white;
                border-top: 1px solid white;
                font-size: 26px;
                font-style: italic;
                font-weight: 300;
                line-height: 28px; /* 107.692% */
                padding-bottom: 25px;
                padding-top: 25px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    position: relative;
                    border: 0;
                    margin: 0 auto;
                    max-width: 650px;
                    font-size: 40px;
                    line-height: 40px;
                }

                &:before {
                    @include media-breakpoint-up(lg) {
                        content: '';
                        width: 70%;
                        height: 1px;
                        position: absolute;
                        background-color: white;
                        left: 0;
                        right: 0;
                        top: 0;
                        margin: 0 auto;
                    }
                }
                &:after {
                    @include media-breakpoint-up(lg) {
                        content: '';
                        width: 70%;
                        height: 1px;
                        position: absolute;
                        background-color: white;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

}
//.ga-banner

.ga-compromise {
    padding-bottom: 83px;
    padding-top: 83px;
    transition: all 0.5s ease-out;

    p {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding-right: 20px;
        padding-left: 20px;
        transition: all 0.5s ease-out;
    }

    img {
        margin-bottom: 10px;
        margin-top: 50px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin-top: -10px;
        }
    }
}
//.ga-compromise

.ga-books {
    background-image: url("/images/background-books.webp");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 490px;
    padding-bottom: 83px;
    padding-top: 83px;
    transition: all 0.5s ease-out;

    @media (min-width: 1440px) {
        background-size: 100% auto;
    }

    p {
        color: $brand-third;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 20px;
        padding-left: 20px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            margin: 0 auto;
            text-align: center;
            width: 520px;
        }

        strong {
            font-weight: 700;
        }
    }

    img {
        margin-bottom: 10px;
        margin-top: 50px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            margin: 50px auto 10px auto;
            width: 580px;
        }
    }
}
//.ga-books

.ga-seal {
    margin-top: 13px;
    transition: all 0.5s ease-out;

    h2 {
        margin-bottom: 32px;
        text-align: center;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin: 120px auto 32px auto;
            text-align: left;
            width: 385px;
        }
    }
    p {
        color: $brand-primary;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 20px;
        padding-left: 20px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            padding: 0;
            text-align: left;
            margin: 0 auto;
            width: 385px;
        }

        strong {
            font-weight: 700;
        }
    }

    img {
        margin-bottom: 10px;
        width: 100%;
        transition: all 0.5s ease-out;
    }

    .imgWrap {
        @include media-breakpoint-up(lg) {
            order: 2;
        }
        img {
            @include media-breakpoint-up(lg) {
                margin-top: 90px;
            }
        }
    }
    .decorationWrap {
        @include media-breakpoint-up(lg) {
            order: 1;
        }

        img {
            @include media-breakpoint-up(lg) {
                width: 100% !important;
            }
        }
    }
    .redWrap {
        background-color: $brand-secondary;
        position: relative;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin-top: -50px;
        }

        p {
            color: $brand-third;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-right: 30px;
            padding-left: 30px;
            padding-top: 90px;
            transition: all 0.5s ease-out;
        }

        &.library {
            padding-bottom: 80px !important;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0 !important;
            }

            .index {
                position: relative;
                z-index: 2;
            }

            .text {
                left: 0;
            }
            .o30 {
                opacity: 0.3;
            }
            .o20 {
                opacity: 0.2;
                top: 105px;

                @include media-breakpoint-up(md) {
                    top: 225px;
                }
            }
        }
    }

    .wrapExtra {
        @include media-breakpoint-up(lg) {
            background-color: $brand-secondary;
            order: 3;
            height: 335px;
            position: relative;
            transition: all 0.5s ease-out;
        }

        .item {
            display: flex;
            flex-direction: row;
            transition: all 0.5s ease-out;

            img {
                @include media-breakpoint-up(lg) {
                    position: relative;
                    width: 50%;
                }
                &.o30 {
                    @include media-breakpoint-up(lg) {
                        opacity: 0.3;
                    }
                }
                &.o20 {
                    @include media-breakpoint-up(lg) {
                        opacity: 0.2;
                    }
                }
            }
        }

    }
}
//.ga-seal

.ga-contact {
    background-image: url("/images/background-contact.webp");
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-bottom: 110px;
    transition: all 0.5s ease-out;

    img {
        transition: all 0.5s ease-out;
        &.top {
            margin-top: -90px;

            @include media-breakpoint-up(lg) {
                margin-top: -345px;
            }
        }
    }

    h2 {
        text-align: center;
        margin-top: 85px;
        transition: all 0.5s ease-out;
    }

    form {
        transition: all 0.5s ease-out;
        .form-group {
            margin-top: 50px;

            label {
                color: #065196;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            input[type=text] {
                background-color: transparent;
                border-bottom: 1px solid #065196;
                transition: all 0.5s ease-out;
            }
        }
    }
}
//.ga-contact

footer {
    background-color: #205C92;
    color: white;
    padding-bottom: 84px;
    padding-top: 84px;
    position: relative;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .wrap-elements {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        img {
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }

        a {
            color: white;
            display: block;
            text-align: center;
            text-decoration: underline;
        }

        .social-address {

            @include media-breakpoint-up(xl) {
                display: flex;
                align-items: center;
            }
            .social-media {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 60px auto 42px auto;
                width: 250px;
                transition: all 0.5s ease-out;

                a {
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: block;
                    height: 40px;
                    width: 40px;
                    transition: all 0.5s ease-out;

                    &.twitter {
                        background-image: url("/images/icon-twitter.svg");
                    }
                    &.facebook {
                        background-image: url("/images/icon-facebook.svg");
                    }
                    &.instagram {
                        background-image: url("/images/icon-instagram.svg");
                    }
                }
            }

            p {
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 125% */
                margin: 40px auto 0 auto;
                text-align: center;
                width: 280px;

                @include media-breakpoint-up(xl) {
                    margin-left: 60px;
                    margin-top: 0;
                    text-align: left;
                }
            }
        }
    }

}

.portfolio-description {
    a {
        color: $brand-primary;

        h3 {
            margin-top: 20px;
            font-weight: 700;
        }
    }
}

.no-books {
    padding-bottom: 80px;
    padding-top: 80px;

    @include media-breakpoint-up(md) {
        padding-bottom: 140px;
        padding-top: 140px;
    }
}