

@charset "UTF-8";

/************** サンプルページ用 **************/
/*
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 100%;
  color: #333;
}
*/


article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  background: transparent;
  text-decoration: none;
  color: inherit;
}

i, address {
  font-style: normal;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1rem 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

svg {
  display: block;
  max-width: 100%;
  height: auto;
  /*width: auto;*/
  vertical-align: top;
  line-height: 0;
}

ins[style] {
  background-color: transparent;
}

span {
  font-family: inherit;
  color: inherit;
}

em {
  font-weight: bold;
  font-style: normal;
}


html{
	font-size:16px;
}



